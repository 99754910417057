import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import { IUnitsInformation } from "../type";

export const Promotion: FC<IUnitsInformation> = (props) => {
  const promotion = props.discountPlans.find((x) => x.autoApply);
  

  return promotion && Boolean(promotion.publicDescription) ? (
    <Box
      sx={(theme) => ({
        width: "100%",
        p: 0.25,
        backgroundColor: alpha(theme.palette.success.main, 0.1),
        color: theme.palette.success.main,
        flexDirection: theme.direction === "ltr" ? "row" : "row-reverse",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        fontSize: theme.typography.pxToRem(15),
      })}
    >
      <LocalOfferIcon fontSize="inherit" sx={{ m: 0.5 }} />
      <Typography variant="subtitle2" fontSize="inherit">
        {promotion.publicDescription}
      </Typography>
    </Box>
  ) : null;
};
