import { FC, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowCircleLeft";

import { IUnitsInformation } from "../../components/UnitsInformation";
import { IFormData } from "./type";
import { InformationPair } from "../../components/InformationPair";
import { getImageSet } from "../../components/UnitsInformation/subComponents/Image";
import { Forms } from "./Forms";
import { useUnitInformation } from "../../context/UnitsInformationProvider";
import { ReservationInformation } from "./ReservationInformation";
import { useConfig } from "../../context/ConfigProvider";
import { GetUnitGroupSummary } from "../../services/UnitGroups";
import { getFacilityAlias } from "../../utils/Aliasing";
import { UnitGroup } from "../../types/UnitGroup";
import { createUnitsGroup } from "../../components/UnitsInformation/type";

interface IReservationForm {
  selectedUnit: IUnitsInformation;
  onBackClick: () => void;
}

export const ReservationForm: FC<IReservationForm> = (props) => {
  const {
    reserve: makeReservation,
    reservation,
    reservationRequest,
    navigateToOMI,
    clearReservation,
  } = useUnitInformation();
  const { widgetParams, configuration } = useConfig();
  const [unitGroupSummary, setUnitGroupSummary] = useState<
    UnitGroup | undefined
  >();

  const typeOfForm = useMemo(() => {
    switch (true) {
      case props.selectedUnit.availbleUnits <=
        configuration.contactForReserveUnitThreshold:
        return "contactUs";
      default:
        return "reservation";
    }
  }, [props.selectedUnit, configuration.contactForReserveUnitThreshold]);

  const imageSet = getImageSet(props.selectedUnit);

  useEffect(() => {
    GetUnitGroupSummary(
      getFacilityAlias(widgetParams, true),
      props.selectedUnit.id
    ).then((resp) => {
      console.log("resp", resp);
      setUnitGroupSummary(resp);
    });
  }, [widgetParams, props.selectedUnit.id]);

  const reserve = (data: IFormData, navigate: boolean = true) => {
    makeReservation(data, navigate, {
      unitGroupId: props.selectedUnit.id,
      typeOfForm,
    });
  };

  const priceUnit =
    reservation && typeOfForm !== "contactUs"
      ? createUnitsGroup(reservation.unit)
      : props.selectedUnit;

  return (
    <div>
      <Box
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={() => {
            props.onBackClick();
            clearReservation();
          }}
        >
          <ArrowBackIcon />
          Back
        </Button>
      </Box>
      <Grid container spacing={2}>
        {typeOfForm === "contactUs" && (
          <Grid item xs={12}>
            <Typography variant="h6">
              Please contact us regarding the availability of this unit
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Typography variant="h5" gutterBottom>
            {props.selectedUnit.name ||
              `${props.selectedUnit.size.length}’ x ${props.selectedUnit.size.width}’`}
          </Typography>
          <InformationPair
            data={[
              {
                label: "Size",
                value: `${props.selectedUnit.size.width}’ x ${props.selectedUnit.size.length}’`,
              },
              {
                label: "Amenities",
                value: props.selectedUnit.amenities.join(","),
              },
              {
                label: "Location",
                value: [
                  unitGroupSummary?.accessType,
                  unitGroupSummary?.floor,
                  unitGroupSummary?.unitEnclosureStatus,
                ]
                  .filter((x) => Boolean(x))
                  .join(", "),
              },
              ...(typeOfForm !== "contactUs" &&
              props.selectedUnit.tiers.length === 0 &&
              !reservation
                ? [
                    {
                      label: "Price",
                      value: priceUnit.discountedPriceStart ? (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography
                            variant="body2"
                            sx={(theme) => ({
                              textDecoration: "line-through",
                              color: theme.palette.grey[500],
                            })}
                          >{`${props.selectedUnit.currencySymbol}${priceUnit.startPrice}`}</Typography>
                          <Typography variant="body2">{`${props.selectedUnit.currencySymbol}${priceUnit.discountedPriceStart}/${props.selectedUnit.pricePer}`}</Typography>
                        </Box>
                      ) : (
                        `${props.selectedUnit.currencySymbol}${priceUnit.startPrice}/${props.selectedUnit.pricePer}`
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            {imageSet.map((image, index) => (
              <Box
                key={index}
                sx={(theme) => ({
                  height: theme.spacing(16),
                  width: theme.spacing(16),
                })}
              >
                <img
                  src={image.url || "https://placehold.co/150"}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt={image.altName || "Unit Group Image"}
                />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      {reservation && reservationRequest ? (
        <ReservationInformation
          typeOfForm={typeOfForm}
          reservationInformation={reservation}
          reservationRequest={reservationRequest}
          onMoveInClick={() => navigateToOMI(reservation, true)}
          onFindAnotherUnitClick={() => {
            props.onBackClick();
            clearReservation();
          }}
        />
      ) : (
        <Forms
          unitGroupInformation={props.selectedUnit}
          unitGroupSummary={unitGroupSummary}
          typeOfForm={typeOfForm}
          onFormDataSubmit={(information) => {
            console.debug("Primary Button", information);
            reserve(information, true);
          }}
          onFormDataSecondarySubmit={(information) => {
            console.debug("Secondary Button", information);
            reserve(information, false);
          }}
          formData={{} as IFormData}
        />
        // <Selection
        //   formType={typeOfForm}
        //   selectedUnit={props.selectedUnit}
        //   selectedTier={selectedTier}
        //   onSelectTier={selectTier}
        //   onFormSubmit={(information) => {
        //     console.log(information);
        //     reserve(information, true);
        //   }}
        //   onFormSecondarySubmit={(information) => {
        //     console.log(information);
        //     reserve(information, false);
        //   }}
        // />
      )}
    </div>
  );
};
