import axios from "axios";
import { IWidgetParams } from "../types/WidgetParams";
import { IUTConfiguration } from "../types/UTConfiguration";

const APP_IDENTIFIER = "US";

const CONFIG_URL =
  import.meta.env.VITE_CONFIG_URL ||
  "https://unitstable-api.webapps.dev.rnl.io";
const CONFIG_KEY = import.meta.env.VITE_CONFIG_KEY || "test";
const CONFIG_SECRET = import.meta.env.VITE_CONFIG_SECRET || "test";

export interface IConfiguration<T> {
  configuration: T;
  providerId: string;
  source: string;
  companyId: string;
  facilityId: string;
  schema: string;
  app: string;
  createdAt: string;
  slug: string;
  name: string;
  description: string;
  id: string;
  schemaId: string;
}

export const getConfiguration = (widgetParams: IWidgetParams) => {
  return axios
    .post<IConfiguration<IUTConfiguration>>(
      `${CONFIG_URL}/api/configuration/fetch`,
      {
        appIdentifier: APP_IDENTIFIER,
        configParams: {
          providerId: widgetParams.providerId,
          source: widgetParams.source,
          companyId: widgetParams.organizationId,
          facilityId: widgetParams.facilityId,
          name: widgetParams.config,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa([CONFIG_KEY, CONFIG_SECRET].join(":")),
        },
      }
    )
    .then((res) => res.data);
};
