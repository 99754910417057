import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  StorEdgeAliasBuilder,
  SiteLinkAliasBuilder,
} from "@storable/polaris-aliases";
import { UnitGroup } from "../types/UnitGroup";
import { UnitCategory } from "../types/UnitCategories";
import { IWidgetParams } from "../types/WidgetParams";
import {
  GetUnitGroups,
  GetUnitCategories,
  IReservationRequest,
  IReservationResponse,
  CreateReservation,
} from "../services";
import { SortData } from "../utils/Sort";
import {
  IContactUsForm,
  IFormData,
  IReservationForm,
  IShortForm,
} from "../containers/ReservationForm/type";
import moment from "moment";
import { DATE_FORMAT } from "../constants";
// import { prepareUrl } from "../utils/Navigation";
import { useConfig } from "./ConfigProvider";
import { EVENTS } from "../constants";

export interface IFilterOption {
  property: "size" | "price" | "amenities" | "category";
  value: string;
  label: string;
}

export interface ISortOption {
  property: "price" | "size";
  order: "asc" | "desc";
  label: string;
}

interface IUnitsInformationContext {
  unitGroups: UnitGroup[];
  readyToDisplayUnitGroups: UnitGroup[];
  unitCategories: UnitCategory[];
  selectedUnitCategory?: UnitCategory;
  fetchUnitGroups: () => void;
  selectCategory: (category?: UnitCategory) => void;
  selectFilter: (filter: IFilterOption) => void;
  isLoadingUnitGroups: boolean;
  filters: IFilterOption[];
  sortOption: ISortOption;
  setSortOption: (sortOption: ISortOption) => void;

  reserve: (
    data: IFormData,
    navigate: boolean,
    otherData?: {
      unitGroupId: string;
      // unitTierId?: string;
      typeOfForm: IReservationForm["typeOfForm"];
    }
  ) => void;
  reservation?: IReservationResponse;
  reservationRequest?: IReservationRequest;
  reserving: boolean;

  priceRange: number[];
  priceRangePossible: number[];
  onPriceRangeChange: (value: number[]) => void;
  clearFilters: () => void;

  navigateToOMI: (
    reservation: IReservationResponse,
    navigate?: boolean
  ) => void;

  clearReservation: () => void;
}

const defaultContext: IUnitsInformationContext = {
  unitGroups: [],
  unitCategories: [],
  fetchUnitGroups: () => {},
  selectCategory: () => {},
  selectFilter: () => {},
  setSortOption: () => {},
  isLoadingUnitGroups: true,
  filters: [],
  sortOption: { property: "size", order: "asc", label: "Unit Size" },
  readyToDisplayUnitGroups: [],
  reserve: () => {},
  reservation: undefined,
  reserving: false,
  priceRange: [0, 0],
  priceRangePossible: [0, 0],
  onPriceRangeChange: () => {},
  clearFilters: () => {},
  navigateToOMI: () => {},
  clearReservation: () => {},
};

const UnitsInformationContext = createContext(defaultContext);

export const useUnitInformation = () => useContext(UnitsInformationContext);

const useUnitInformationProvider = (
  params: IWidgetParams
): IUnitsInformationContext => {
  const [isLoadingUnitGroups, setIsLoadingUnitGroups] = useState<boolean>(true);
  const [unitGroups, setUnitGroups] = useState<UnitGroup[]>([]);
  const [unitCategories, setUnitCategories] = useState<UnitCategory[]>([]);
  const [selectedUnitCategory, setSelectedUnitCategory] =
    useState<UnitCategory>();
  const [filters, setFilters] = useState<IFilterOption[]>([]);
  const [sortOption, setSortOption] = useState<ISortOption>({
    property: "size",
    order: "asc",
    label: "Unit Size",
  });
  const { triggerEvent } = useConfig();
  const [reservationRequest, setReservationRequest] =
    useState<IReservationRequest>();
  const [reservation, setReservation] = useState<IReservationResponse>();
  const [reserving, setReserving] = useState<boolean>(false);
  const [priceRange, setPriceRange] = useState<number[]>([0, 0]);
  const [priceRangePossible, setPriceRangePossible] = useState<number[]>([
    0, 0,
  ]);

  const fetchUnitGroups = useCallback(() => {
    setIsLoadingUnitGroups(true);
    const facilityAlias =
      params.providerId === "se"
        ? StorEdgeAliasBuilder.buildFacilityAlias(
            params.organizationId,
            params.facilityId
          )
        : SiteLinkAliasBuilder.buildFacilityAlias(
            params.organizationId,
            params.facilityId
          );
    const companyAlias =
      params.providerId === "se"
        ? StorEdgeAliasBuilder.buildOrganizationAlias(params.organizationId)
        : SiteLinkAliasBuilder.buildOrganizationAlias(params.organizationId);
    Promise.all([
      GetUnitGroups(encodeURI(facilityAlias.toString())),
      GetUnitCategories(encodeURI(companyAlias.toString())),
    ])
      .then(([fetchedUnitGroups, fetchedUnitCategories]) => {
        triggerEvent(EVENTS.ON_DATA_LOAD, {});
        setUnitGroups(fetchedUnitGroups);
        setUnitCategories(fetchedUnitCategories);
        const prices = fetchedUnitGroups.map((group) => group.price);
        setPriceRange([Math.min(...prices), Math.max(...prices)]);
        setPriceRangePossible([Math.min(...prices), Math.max(...prices)]);
      })
      .finally(() => {
        setIsLoadingUnitGroups(false);
      });
  }, [params.facilityId, params.organizationId, params.providerId]);

  useEffect(() => {
    fetchUnitGroups();
  }, [params.facilityId, params.organizationId, fetchUnitGroups]);

  const selectFilter = (filter: IFilterOption) => {
    setFilters((curretFilters) => {
      // check if same filter is already in list
      const filterIndex = curretFilters.findIndex(
        (fltr) =>
          fltr.property === filter.property && fltr.value === filter.value
      );
      if (filterIndex === -1) {
        const updatedFilters = [...curretFilters, filter];
        triggerEvent(EVENTS.ON_FILTERS_UPDATE, updatedFilters);
        return updatedFilters;
      } else {
        const updatedFilters = curretFilters.filter(
          (_fltr, index) => index !== filterIndex
        );
        triggerEvent(EVENTS.ON_FILTERS_UPDATE, updatedFilters);
        return updatedFilters;
      }
    });
  };

  const readyToDisplayUnitGroups = useMemo(() => {
    // First apply filters
    // Priortization => Price, Size, Amenities
    let filteredUnitGroups = [...unitGroups];
    //Price filter
    filteredUnitGroups = filteredUnitGroups.filter((unitGroup) => {
      return (
        unitGroup.price >= priceRange[0] && unitGroup.price <= priceRange[1]
      );
    });
    // Ameneities filter
    const amenitiesFilters = filters.filter((x) => x.property === "amenities");
    if (amenitiesFilters.length > 0) {
      filteredUnitGroups = filteredUnitGroups.filter((unitGroup) => {
        return amenitiesFilters.every((filter) => {
          return unitGroup.amenities.some(
            (amenity) => amenity.name === filter.value
          );
        });
      });
    }
    // Size filter
    const sizeFilters = filters.filter((x) => x.property === "size");
    if (sizeFilters.length > 0) {
      filteredUnitGroups = filteredUnitGroups.filter((unitGroup) => {
        return sizeFilters.some((filter) => {
          return unitGroup.size.startsWith(filter.value);
        });
      });
    }
    // let filteredUnitGroups =
    //   filters.length > 0
    //     ? unitGroups.filter((unitGroup) => {
    //         return filters.some((filter) => {
    //           switch (filter.property) {
    //             default:
    //               return false;
    //             case "category":
    //               return unitGroup.unitCategories.some(
    //                 (category) => category.id === filter.value
    //               );
    //             case "amenities":
    //               return unitGroup.amenities.some(
    //                 (amenity) => amenity.name === filter.value
    //               );
    //             case "size":
    //               return unitGroup.size === filter.value;
    //             case "price":
    //               return unitGroup.price <= Number(filter.value);
    //           }
    //         });
    //       })
    //     : unitGroups;
    if (selectedUnitCategory) {
      filteredUnitGroups = filteredUnitGroups.filter((unitGroup) => {
        return unitGroup.unitCategories.some(
          (category) => category.id === selectedUnitCategory.id
        );
      });
    }
    // finally apply sort
    return SortData(
      filteredUnitGroups,
      (utGroup) => {
        if (sortOption.property === "size") {
          // in a string 20 is considered smaller than 2, 02 is considered smaller than 20
          // adding a pad of 0 to single digit number will work,
          // the assumpotion is that max number will be only 2 digits in length & height
          return `${String(utGroup.width).padStart(2, "0")}x${String(
            utGroup.length
          ).padStart(2, "0")}`;
          // return utGroup.width * utGroup.length;
        }
        if (sortOption.property === "price") {
          return utGroup.price;
        }
        return 0;
      },
      sortOption.order
    );
  }, [sortOption, unitGroups, filters, selectedUnitCategory, priceRange]);

  // const unitCategoriesSet = Array.from(
  //   new Set(unitGroups.flatMap((group) => group.unitCategories))
  // );

  const navigateToOMI = (
    reservation: IReservationResponse,
    navigate: boolean = false
  ) => {
    triggerEvent(EVENTS.ON_RESERVATION, { reservation, navigate });
    if (navigate) {
      // TODO: to be re-thought later
      console.debug("Navigating to OMI");
      // window.open(
      //   prepareUrl(
      //     configuration.omiUrl,
      //     [
      //       ["fms", "se"],
      //       ["organizationId", params.organizationId],
      //       ["facilityId", params.facilityId],
      //       ["reservationId", reservation.alias],
      //       ["unitId", reservation.unitId],
      //     ],
      //     "query"
      //   ),
      //   "_blank"
      // );
    }
  };

  const reserve = (
    data: IFormData,
    navigate: boolean = true,
    otherData?: {
      unitGroupId: string;
      // unitTierId?: string;
      typeOfForm: IReservationForm["typeOfForm"];
    }
  ) => {
    const dataToSend: IReservationRequest = {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      emailAddress: data.email,
      unitGroupId: otherData!.unitGroupId,
      isReservation: otherData?.typeOfForm !== "contactUs",
      unitGroupTierId: data.unitGroupTierId,
      desiredMoveInDate: (otherData?.typeOfForm !== "contactUs"
        ? moment((data as IShortForm).moveInDate, DATE_FORMAT)
        : moment()
      ).format("YYYY-MM-DDT00:00:00.000Z"),
      onlineMoveInUrl: window.location.href, // TODO: Use Configurations
      notes:
        otherData?.typeOfForm === "contactUs"
          ? (data as IContactUsForm).notes
          : undefined,
    };
    if (data.promoId) {
      dataToSend.discountPlans = [data.promoId];
    }
    // eslint-disable-next-line no-extra-boolean-cast
    if (!Boolean(data?.unitGroupTierId)) {
      delete dataToSend.unitGroupTierId;
    }
    triggerEvent(EVENTS.ON_RESERVATION_FORM_SUBMIT, dataToSend);
    setReservationRequest(dataToSend);
    setReserving(true);
    CreateReservation(dataToSend)
      .then((response) => {
        console.log("reservation created", response);
        setReservation({ ...response, notes: dataToSend.notes });
        navigateToOMI(response, navigate);
      })
      .catch((err) => {
        triggerEvent(EVENTS.ON_RESERVATION_FORM_SERVER_ERROR, err);
        console.error("Error Creating Reservation", err);
      })
      .finally(() => {
        setReserving(false);
      });
  };

  return {
    isLoadingUnitGroups,
    unitGroups,
    unitCategories,
    selectedUnitCategory,
    selectCategory: setSelectedUnitCategory,
    selectFilter,
    fetchUnitGroups,
    filters,
    sortOption,
    readyToDisplayUnitGroups,
    setSortOption,
    reserve,
    reserving,
    reservation,
    reservationRequest,
    priceRange,
    priceRangePossible,
    onPriceRangeChange: setPriceRange,
    clearReservation: () => {
      setReservation(undefined);
      setReservationRequest(undefined);
    },
    clearFilters: () => {
      setPriceRange(priceRangePossible);
      setFilters([]);
      triggerEvent(EVENTS.ON_FILTERS_UPDATE, []);
    },
    navigateToOMI,
  };
};

export const UnitsInformationProvider: FC<PropsWithChildren<IWidgetParams>> = ({
  children,
  ...widgetParams
}) => {
  const providerData = useUnitInformationProvider(widgetParams);

  return (
    <UnitsInformationContext.Provider value={providerData}>
      {children}
    </UnitsInformationContext.Provider>
  );
};
