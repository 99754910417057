import {
  createContext,
  useContext,
  useState,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
} from "react";
import { IWidgetParams } from "../types/WidgetParams";
import { widgetParams } from "../mock/widgetParams";
import {
  IUTConfiguration,
  defaultUTConfiguration,
} from "../types/UTConfiguration";
import { getConfiguration } from "../services/Configuration";

const CUSTOM_EVENT_TRIGGER_NAME = "storable-webapp-ut-event";

type EventTriggerFn = (
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventData: Record<string, any>
) => void;

type EventDispatcherFn = (customEvent: CustomEvent) => void;

interface IConfigContext {
  widgetParams: IWidgetParams;
  configuration: IUTConfiguration;
  refreshConfig: () => void;
  triggerEvent: EventTriggerFn;
}

const defaultConfigContext: IConfigContext = {
  widgetParams,
  configuration: defaultUTConfiguration,
  refreshConfig: () => {},
  triggerEvent: () => {},
};

const ConfigContext = createContext(defaultConfigContext);

export const useConfig = () => useContext(ConfigContext);

const useConfigProvider = (
  widgetParamsInfo: IWidgetParams,
  onEvent: EventDispatcherFn
): IConfigContext => {
  const [config, setConfig] = useState<IUTConfiguration>(
    defaultUTConfiguration
  );

  const fetchConfig = useCallback(() => {
    getConfiguration(widgetParamsInfo)
      .then((resp) => {
        setConfig({ ...defaultUTConfiguration, ...resp.configuration });
      })
      .catch((err) => {
        console.error("Error fetching configuration", err);
        setConfig(defaultUTConfiguration);
      });
  }, [widgetParamsInfo]);

  useEffect(fetchConfig, []);

  const triggerEvent: EventTriggerFn = (eventName, eventData) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).storableUSWidget?.onEvent?.({
      event: eventName,
      data: eventData,
    });
    onEvent(
      new CustomEvent(CUSTOM_EVENT_TRIGGER_NAME, {
        detail: { eventName, data: eventData },
        bubbles: true,
        composed: true,
      })
    );
  };

  return {
    widgetParams: { ...widgetParams, ...widgetParamsInfo },
    configuration: config,
    refreshConfig: fetchConfig,
    triggerEvent,
  };
};

export const ConfigProvider: FC<
  PropsWithChildren<Partial<IWidgetParams> & { onEvent: EventDispatcherFn }>
> = ({ children, onEvent, ...widgetParamsData }) => {
  const providerValue = useConfigProvider(
    {
      ...widgetParams,
      ...widgetParamsData,
    },
    onEvent
  );
  return (
    <ConfigContext.Provider value={providerValue}>
      {children}
    </ConfigContext.Provider>
  );
};
