import {
  StorEdgeAliasBuilder,
  SiteLinkAliasBuilder,
} from "@storable/polaris-aliases";
import { IWidgetParams } from "../types/WidgetParams";

export const getFacilityAlias = (
  widgetParams: IWidgetParams,
  encode = false
): string => {
  const facilityAlias =
    widgetParams.providerId === "se"
      ? StorEdgeAliasBuilder.buildFacilityAlias(
          widgetParams.organizationId,
          widgetParams.facilityId
        )
      : SiteLinkAliasBuilder.buildFacilityAlias(
          widgetParams.organizationId,
          widgetParams.facilityId
        );

  return encode
    ? encodeURIComponent(facilityAlias.toString())
    : facilityAlias.toString();
};

export const getCompanyAlias = (
  widgetParams: IWidgetParams,
  encode = false
): string => {
  const companyAlias =
    widgetParams.providerId === "se"
    
      ? StorEdgeAliasBuilder.buildCompanyAlias(widgetParams.organizationId)
      : SiteLinkAliasBuilder.buildCompanyAlias(widgetParams.organizationId);

  return encode
    ? encodeURIComponent(companyAlias.toString())
    : companyAlias.toString();
};
